import { MappaComponent } from "./../mappa/mappa.component";
import { Component, Input, NgZone, OnInit, ViewChild } from "@angular/core";
import { NbDialogRef, NbIconLibraries } from "@nebular/theme";
import { FileUploadService } from "../../@core/backend/common/services/file-upload.service";
import { RelationsService } from "../../@core/backend/common/services/relations.service";
import { UsersService } from "../../@core/backend/common/services/users.service";
import { ModalGenericComponent } from "../modal-generic/modal-generic.component";

@Component({
  selector: "ngx-modal-mappa",
  templateUrl: "./modal-mappa.component.html",
  styleUrls: ["./modal-mappa.component.scss"],
})
export class ModalMappaComponent
  extends ModalGenericComponent
  implements OnInit
{
  @ViewChild("mappa", { static: true }) private mappaComponent: MappaComponent;
  @Input() mode: string = "edit";
  @Input() idMappa: number;
  @Input() idOggetto: number;
  visibleMappa: boolean = false;
  ngOnInit() {
    super.ngOnInit();
    this.visibleMappa=true;
  }
  constructor(
    ref: NbDialogRef<ModalGenericComponent>,
    iconsLibrary: NbIconLibraries,
    ngZone: NgZone
  ) {
    super(ref, iconsLibrary, ngZone);
  }

  resize() {
    super.resize();
    this.mappaComponent.resize();
  }
  changeMappa(idMappa) {
    console.log("changeMappa", idMappa, this.idMappa);
    if (idMappa && idMappa!=this.idMappa) {

      this.visibleMappa=false;
      this.idMappa = idMappa;

      setTimeout(() => {
        this.visibleMappa=true;
      } , 100);
    }
  }
}
